import React from "react";
import { graphql } from "gatsby";

import { useProductNodes } from "../hooks/use-products-nodes";

import Layout from "../layout";

import SEO from "../components/seo";
import ContentPreview from "../components/content-preview";
import LangLink from "../components/lang-link";
import ProductCardSlider from "../components/product-card-slider";

import HomlanBanner from "../images/homlan-banner.png";

const HomePage = ({ data }) => {
  const metaData = data.prismicMetaData.data;
  const lang = data.prismicMetaData.lang;
  const productsData = useProductNodes(lang);
  return (
    <Layout lang={lang}>
      <SEO
        title="home"
        keywords={[`Monitor`, `Viewtec`, `Homlan`]}
        lang={lang}
        description={metaData.company_description} />
      <main id="homepage" className="responsive-paddings--bottom">
        {/* <TitleHeader background={HomlanBanner}><div dangerouslySetInnerHTML={{ __html: metaData.company_name_styled.html }} /></TitleHeader> */}
        <img id="banner" alt="Homlan Banner" src={HomlanBanner} />
        <LangLink lang={lang} to="/company-history" className="noDecoration">
          <section id="company-description-section" className="responsive-paddings">
            <ContentPreview lang={lang}>
              {metaData.company_description}
            </ContentPreview>
          </section>
        </LangLink>
        <section id="product-display">
          <ProductCardSlider products={productsData} lang={lang} />
        </section>
      </main>
    </Layout>
  )
}

export default HomePage;

export const query = graphql`
  query($slug: String!) {
    prismicMetaData(fields: { slug: { eq: $slug } }) {
        lang
        data {
          company_name
          company_name_styled {
            html
          }
          company_description
        }
    }
  }
`;