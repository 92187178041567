import React from "react";
import ProductCard from '../components/product-card';
import { Link } from 'gatsby';

export default class ProductCardSlider extends React.Component {
    constructor(props) {
        super(props);
        this.numOfRendered = 9;
        this.state = {
            currentCard: 0
        }
        this.currentContainer = React.createRef();
        this.lastClicked = 0;
        this.intervalTime = 250000;
    }
    nextCard = () => {
        this.addCurrent(1);
    }
    addCurrent = (n) => {
        this.setState((state) => {
            if (state.currentCard + n < 0) {
                //console.log("settingto " + (state.currentCard + n + this.props.products.length));
                return { currentCard: state.currentCard + n + this.props.products.length }
            }
            if (state.currentCard + n >= this.props.products.length) {
                //console.log("settingto " + (state.currentCard - this.props.products.length + n));
                return { currentCard: state.currentCard - this.props.products.length + n }
            }
            return { currentCard: state.currentCard + n }
        });

    }
    componentDidMount() {
        //console.log("Last clicked diff: " + new Date().getTime() - this.lastClicked > 3000);
        this.interval = setInterval(
            () => {
                if (new Date().getTime() - this.lastClicked > 3000)
                    this.nextCard();
            }, this.intervalTime);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        let renderedProducts;
        const { products } = this.props;
        const { currentCard } = this.state;
        //console.log("current: " + this.state.currentCard);
        if (this.numOfRendered + currentCard > products.length) {
            //console.log("buffer render");
            renderedProducts = products.slice(currentCard);
            renderedProducts = renderedProducts.concat(products.slice(0, currentCard - products.length + this.numOfRendered));
        } else {
            renderedProducts = products.slice(currentCard, this.numOfRendered + currentCard);
        }
        return (
            <div className="product-card-slider remove-list-styles">
                {renderedProducts.map(({ fields, data }, i) => {
                    const current = Math.floor(this.numOfRendered / 2);
                    const isCurrent = current === i;
                    return (
                        <button className={`remove-button-styles product-card-slider__card-container ${!isCurrent ?
                            'product-card-slider__card-container--faded' : ''}`}
                            onClick={() => {
                                this.addCurrent(i - current, products.length);
                                this.lastClicked = new Date().getTime();
                            }}
                            style={{
                                transform: `translate(${(i * 9) - current * 9 - 4.5}em,0) ${!isCurrent ?
                                    'scale(.8, .8)' : ''}`
                            }}
                            key={`product-card-${data.product_name.text}`}>
                            <ProductCard img={{
                                src: data.image_gallery[0].image.url,
                                alt: data.image_gallery[0].image.alt,
                                fluid: data.image_gallery[0].image.localFile && data.image_gallery[0].image.localFile.childImageSharp.fluid
                            }}
                                title={data.display_name.text}
                                inches={data.panel_size}
                                categories={[]}
                                productName={data.product_name.text} />
                            {isCurrent && <Link
                                className="product-card-slider__card-link"
                                to={fields.slug}
                                lang={this.props.lang}
                                aria-label={`Link to product ${data.product_name.text}`} />}
                        </button>
                    )
                })}
            </div>
        )
    }
}