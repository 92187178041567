import { useStaticQuery, graphql } from 'gatsby';

export const useLayoutData = () => {
  const { nodes } = useStaticQuery(graphql`
      query {
        allPrismicLayoutComponentData {
          nodes {
            lang
            data {
              nav_buttons {
                display_name
                slug
              }
              more_text
              footer_address
              map_link_text
              footer_phone
              footer_fax
              footer_email
              uniform_numbers
            }
          }
        }
      }`).allPrismicLayoutComponentData;
  return nodes;
}
