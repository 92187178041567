import React from 'react';
import { useLayoutData } from "../hooks/use-layout-data";
import { FaArrowRight } from 'react-icons/fa';

const MoreBtn = ({lang}) => {
    const layoutData = useLayoutData().find(node => node.lang === lang);
    return (
        <div className="MoreBtn">
            {layoutData.data.more_text}<span className="align-center-fa"><FaArrowRight /></span>
        </div>
    )
}

export default MoreBtn;