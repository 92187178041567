import { useStaticQuery, graphql } from 'gatsby';

export const useProductNodes = (lang) => {
  let { nodes } = useStaticQuery(graphql`
      query {
        allPrismicProducts {
          nodes {
            fields {
              slug
            }
            lang
            data {
              product_name {
                text
              }
              display_name {
                text
              }
              product_description {
                html
              }
              panel_size
              categories {
                category {
                  document {
                    data {
                      name
                    }
                  }
                }
              }
              image_gallery {
                image {
                  url
                  alt
                  localFile {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
              files {
                file_name
                file {
                  url
                }
              }
              ruten_buy_link
              body {
                __typename
                ... on PrismicProductsBodyTable {
                  primary {
                    title {
                      text
                    }
                  }
                  items {
                    row_item {
                      html
                    }
                    row_category
                  }
                }
              }
            }
          }
        }
      }
      `).allPrismicProducts;
  nodes = lang ? nodes.filter(node => node.lang === lang) : nodes;
  return nodes;
}
