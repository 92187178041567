import React from 'react';
import MoreBtn from './more-btn';

const ContentPreview = ({children, lang}) => (
    <div className="content-preview">
        <div className="content-preview__content">{children}</div>
        <span className="content-preview__more"><MoreBtn lang={lang}/></span>
    </div>
)

export default ContentPreview;